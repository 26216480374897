<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="105px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="园区名称：" prop="title">
              {{formData.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="园区电话：">
              {{formData.mobile}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="园区地址：" prop="title">
              {{formData.location}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item label="园区封面">
                <img class="img" @click="showImg(formData.cover, '园区封面')" :src="formData.cover" alt="">
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-form-item label="园区介绍：" prop="type">
            <div v-html="formData.description"></div>
          </el-form-item>
        </el-row>
        <el-row class="areaSplit">
          <el-button type="primary" @click="addParkNews">新增资讯</el-button>
          <el-form-item label="园区资讯">
            <el-table :data="tableData"
                    border
                    v-loading="loadingList"
                    row-key="id">
            <el-table-column fixed prop="title" label="资讯标题" min-width="100">
              <template v-slot="{ row }">
                <router-link :to="{name: 'parkNewsDetail', query: { id: row.id }}"><el-button type="text">{{row.title}}</el-button></router-link>
              </template>
            </el-table-column>
            <el-table-column prop="publisher" label="发布者" min-width="80"></el-table-column>
            <el-table-column label="园区资讯封面" min-width="70">
              <template v-slot="{ row }">
                <img :src="row.cover" class="displayImg" alt="">
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                min-width="50">
              <template v-slot="{ row }">
                <el-button type="text" @click="handleDeleteNews(row)">删除</el-button>
                <el-button type="text" @click="handleEditNews(row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
            <el-pagination
                :current-page.sync="pageObj.currentPage"
                :total="pageObj.total"
                :page-sizes="pageObj.pageSizes"
                :page-size="pageObj.pageSize"
                @current-change = "handlerPageChange"
                @size-change = "handlerSizeChange"
                :layout="pageObj.layout"></el-pagination>
          </el-form-item>
        </el-row>
        <el-row class="areaSplit">
          <el-button @click="backToList">返回列表</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import globalConfig from 'config/global'
import { execute } from "./option"
export default {
  name: "caseDetail",
  data() {
    return {
      id: null,
      formData: {},
      tableData: [],
      loadingList: false,
      pageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      }
    }
  },
  created() {
    this.id = this.$route.params.parkId
    this.tryGetPolicyById()
    this.tryGetParkNewsList()
  },
  methods: {
    ...mapActions(['getParkById', 'getParkNewsList', 'deleteParkNews']),
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    checkSuccess() {},
    checkFail() {},
    backToList() {
      this.Event.$emit('closeTab')
      this.$router.push({
        name: 'parkList'
      })
    },
    // 新增资讯
    addParkNews() {
      execute('addOrEditParkNews', {
        optType: 'add',
        detail: { id: this.id },
        callback: this.tryGetParkNewsList
      })
    },
    // 删除资讯
    handleDeleteNews(row) {
      this.$confirm('确认删除该园区资讯吗？').then(() => {
        this.deleteParkNews(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.tryGetParkNewsList()
        })
      })
    },
    handleEditNews(row) {
      execute('addOrEditParkNews', {
        optType: 'edit',
        detail: { ...row },
        callback: this.tryGetParkNewsList
      })
    },
    // 获取园区资讯
    tryGetParkNewsList() {
      this.getParkNewsList({
        page: this.pageObj.currentPage,
        size: this.pageObj.pageSize,
        parkId: this.id
      }).then(res => {
        const { recordList, total } = res
        this.tableData = recordList || []
        this.pageObj.total = total
        // this.tableData = recordList
      })
    },
    handlerPageChange(page) {
      this.pageObj.currentPage = page
      this.getListByPage()
    },
    handlerSizeChange(size) {
      this.pageObj.pageSize = size
      this.getListByPage()
    },
    // 获取园区详情
    tryGetPolicyById() {
      this.getParkById(this.id).then(res => {
        this.formData = res
      })
    },
  }
}
</script>
<style>
.displayImg {
  display: block;
  max-width: 120px;
}
</style>
<style scoped lang="scss">
.areaSplit {
  border-top: 1px solid #909399;
  padding-top: 15px;
  //max-height: 450px;
  .el-col{
    //width: 100%;
    height: 100%;
    .img{
      width: 100%;
      height: 100%;
      max-height: 400px;
      cursor: pointer;
    }
  }
}
</style>
